import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

export const qsLeadSlice = createSlice({
  name: "qsLead",
  initialState: {
    loginError: "aaa",
    message: null,
    loading: false,
    formSubmitLoading: false,
    apiError: {},
    listCount: 0,
    drawer: false,
    purchaseHistoryList: [],
    saleHistoryList: [],
    saleListCount: 0,
    repairAmountList: [],
    reEstimationList: [],
    qsLeadList: [],
    purchaseEnquiryList: [],
    qsLeadData: {
     
    },
    purchaseRejectData: {},
    vehicleParams: {
      start_date: "",
      end_date: "",

      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
      currentSort: "code",
      sortOrder: "-",
    },
    godownParams: {
      start_date: "",
      end_date: "",
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
      currentSort: "code",
      sortOrder: "-",
    },
    imageLooading: false,
    checkApprove: false,
    vehicleVersion: {},
    insuranceData: {},
    customerByIdList: {},
    gateInDataById: {},
    vehicleData: {},
    RejectMode: false,
    qsLeadParams: {
      start_date: "",
      end_date: "",
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
      // currentSort: "code",
      // sortOrder: "-",
    },
    rejectReasonObjData: {},
    filterStatus: false,
    vehicleHistoryCount: 0,
    saleHistoryCount: 0,
  },
  reducers: {
    tabClearData: (state, action) => {
      return {
        ...state,
        vehicleHistoryCount: 0,
        saleHistoryCount: 0,
      };
    },
    getQsLeadList: (state, action) => {
      if (state.qsLeadParams?.page == 1) {
        return {
          ...state,
          loading: true,
          qsLeadList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    getGateInListById: (state, action) => {
      console.log("hello world");
      if (state.qsLeadParams?.page == 1) {
        return {
          ...state,
          loading: true,
          qsLeadList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    gateInListSuccessful: (state, action) => {
      var noofpages = Math.ceil(
        action.payload.response.count / state.qsLeadParams.page_size
      );
      return {
        ...state,
        listCount: action.payload.response.count,
        qsLeadList: action.payload.response.results,
        // qsLeadList: [
        //   ...state.qsLeadList,
        //   ...action.payload.response.results,
        // ],
        loading: false,
        qsLeadParams: {
          ...state.qsLeadParams,
          no_of_pages: noofpages,
        },
      };
    },

    getGateInListByIdSuccessful: (state, action) => {
      console.log("action payload..", payload);
      return {
        ...state,
        qsLeadList: action.payload,
        loading: false,
      };
    },

    clearPurchaseList: (state, action) => {
      return {
        ...state,
        loading: true,
        qsLeadList: [],
      };
    },
    SetVehicleParams: (state, action) => {
      return {
        ...state,
        vehicleParams: action.payload,
      };
    },
    SetGodownParams: (state, action) => {
      return {
        ...state,
        godownParams: action.payload,
      };
    },

    setQsLeadData: (state, action) => {
      return{
        ...state,
        qsLeadData:{}
      }


    },

    // Purchase Vehicle Historty

    getPurchaseHistory: (state, action) => {
      return {
        ...state,
        loading: true,
        purchaseHistoryList: [],
      };
    },

    purchaseHistoryListSuccessful: (state, action) => {
      var noofpages = Math.ceil(
        action.payload.response.count / state.qsLeadParams.page_size
      );

      return {
        ...state,
        vehicleHistoryCount: action.payload.response.count,
        purchaseHistoryList: action.payload.response.results,
        loading: false,
        formSubmitLoading: false,
        qsLeadParams: {
          ...state.qsLeadParams,
          no_of_pages: noofpages,
        },
      };
    },

    //  Sale Vehicle Historty

    getSaleHistory: (state, action) => {
      return {
        ...state,
        loading: true,
        saleHistoryList: [],
      };
    },

    saleHistoryListSuccessful: (state, action) => {
      var noofpages = Math.ceil(
        action.payload.response.count / state.qsLeadParams.page_size
      );

      return {
        ...state,
        // saleListCount: action.payload.response.count,
        saleHistoryCount: action.payload.response.count,
        saleHistoryList: action.payload.response.results,
        loading: false,
        qsLeadParams: {
          ...state.qsLeadParams,
          no_of_pages: noofpages,
        },
      };
    },

    // Repair Amount

    getRepairAmount: (state, action) => {
      return {
        ...state,
        loading: true,
        listCount: 0,
        repairAmountList: [],
      };
    },

    repairAmountListSuccessful: (state, action) => {
      var noofpages = Math.ceil(
        action.payload.response.count / state.qsLeadParams.page_size
      );

      return {
        ...state,
        listCount: action.payload.response.count,
        repairAmountList: action.payload.response.results,
        loading: false,
        qsLeadParams: {
          ...state.qsLeadParams,
          no_of_pages: noofpages,
        },
      };
    },

    // Re Estimation

    getReEstimation: (state, action) => {
      return {
        ...state,
        loading: true,
        listCount: 0,
        reEstimationList: [],
      };
    },

    reEstimationListSuccessful: (state, action) => {
      var noofpages = Math.ceil(
        action.payload.response.count / state.qsLeadParams.page_size
      );

      return {
        ...state,
        listCount: action.payload.response.count,
        reEstimationList: action.payload.response.results,
        loading: false,
        qsLeadParams: {
          ...state.qsLeadParams,
          no_of_pages: noofpages,
        },
      };
    },

    addQsLead: (state, action) => {
      return {
        ...state,
        formSubmitLoading: true,
      };
    },
    addQsLeadSuccessful: (state, action) => {
      return {
        ...state,
        formSubmitLoading: false,
      };
    },
    purchaseClear: (state, action) => {
      return {
        ...state,
        loading: false,
        qsLeadData: {},
        vehicleVersion: {},
        insuranceData: {},
      };
    },
    getPurchaseDoc: (state, action) => {
      return {
        ...state,
        loading: true,
        qsLeadData: {},
        vehicleVersion: {},
        insuranceData: {},
      };
    },
    purchaseDocSuccessful: (state, action) => {
      return {
        ...state,
        qsLeadData: {
          ...action.payload,
          dealer_id: action.payload.dealer?.id,
          financierbank_id: action.payload.financierbank?.id,
          tokendate:
            action.payload.tokendate == null
              ? null
              : moment(action.payload?.tokendate),
          insurancecompany_id: action.payload.insurancecompany?.id,
          insuranceexpdate:
            action.payload.insuranceexpdate == null
              ? null
              : moment(action.payload?.insuranceexpdate),
          customer_id: action.payload.customer?.id,
          internalfinancier_id: action.payload.internalfinancier?.id,
          counter_id: action.payload.counter?.id,
          vehicle_id: action.payload.vehicle?.id,
          color_id: action.payload.color?.id,
          mcinternal_id: action.payload.mcinternal?.id,
          addressproof1_id: action.payload.addressproof1?.id,
          addressproof2_id: action.payload.addressproof2?.id,
          externalmcbank_id: action.payload.externalmcbank?.id,
          mcinternal_id: action.payload.mcinternal?.id,
          insurance_id: action.payload.insurance?.code,
          // insuranceamount: action.payload.insurance?.customeramount,
          insuranceamount: action.payload.insuranceamount,
          requestedamount: action.payload.insurance?.requestedamount,
        },
        loading: false,
        saleListCount: 0,
      };
    },

    getQsLeadByID: (state, action) => {
      return {
        ...state,
        loading: true,
        gateInDataById: {},
      };
    },
    qsLeadByIDSuccessful: (state, action) => {
      return {
        ...state,
        qsLeadData: {
          ...action.payload.response,
          vehicle_id: action.payload.response.vehicle ? {
            vehicle_name : action.payload.response.vehicle.vehicleno,
            vehicle_id : action.payload.response.vehicle.id,
          } : "",
          godown_id: action.payload.response.godown ? {
            godown_name : action.payload.response.godown.name,
            godown_id : action.payload.response.godown.id,
          } : "",
          lotno: action.payload.response.lot_no,
          description: action.payload.response.out_description,
        },
        loading: false,
      };
    },
    getVehicleVersion: (state, action) => {
      return {
        ...state,
        loading: true,
        vehicleVersion: action.payload,
      };
    },
    getInsuranceData: (state, action) => {
      return {
        ...state,
        loading: true,
        insuranceData: action.payload,
      };
    },
    getVehicleData: (state, action) => {
      return {
        ...state,
        loading: true,
        vehicleData: {},
      };
    },
    vehicleDataSuccessful: (state, action) => {
      return {
        ...state,
        vehicleData: action.payload.response,
        loading: false,
      };
    },
    InputChangeValue: (state, action) => {
      return {
        ...state,
        qsLeadData: {
          ...state.qsLeadData,
          ...action.payload
        },
      };
    },

    // InputChangeValue: (state, action) => {
    //   switch (action.payload.key) {
    //     case "partytype":
    //       if (action.payload.value == 4 || action.payload.value == 5) {
    //         return {
    //           ...state,
    //           qsLeadData: {
    //             counter_id: state.qsLeadData.counter_id,
    //             mc_internal_type: 3,
    //             [action.payload.key]: action.payload.value,
    //           },
    //         };
    //       } else {
    //         return {
    //           ...state,
    //           qsLeadData: {
    //             counter_id: state.qsLeadData.counter_id,
    //             [action.payload.key]: action.payload.value,
    //           },
    //         };
    //       }
    //     case "hypothecation":
    //       return {
    //         ...state,
    //         qsLeadData: {
    //           ...state.qsLeadData,
    //           [action.payload.key]: action.payload.value,
    //           hypothecationisinternal: false,
    //           internalfinancier_id: null,
    //           externalfinanciername: null,
    //           financierbank_id: null,
    //           financieraccountnumber: null,
    //           financierifsccode: null,
    //           loanstatus: false,
    //           loanamount: null,
    //           loannumber: null,
    //         },
    //       };

    //     case "vehicle_id":
    //       return {
    //         ...state,
    //         qsLeadData: {
    //           ...state.qsLeadData,
    //           [action.payload.key]: action.payload.value,
    //           insurancecompany_id: null,
    //           insurancenumber: null,
    //           insuranceexpdate: null,
    //           is_insurance_raised: null,
    //           raise_insurance: null,
    //           requestedamount: null,
    //           insurance_id: null,
    //           insuranceamount: null,
    //           customeramount: null,
    //         },
    //       };

    //     case "loanstatus":
    //       return {
    //         ...state,
    //         qsLeadData: {
    //           ...state.qsLeadData,
    //           [action.payload.key]: action.payload.value,
    //           hypothecationisinternal: false,
    //           internalfinancier_id: null,
    //           externalfinanciername: null,
    //           financierbank_id: null,
    //           financieraccountnumber: null,
    //           financierifsccode: null,
    //           loanamount: null,
    //         },
    //       };

    //     case "hypothecationisinternal":
    //       return {
    //         ...state,
    //         qsLeadData: {
    //           ...state.qsLeadData,
    //           [action.payload.key]: action.payload.value,
    //           externalfinanciername: null,
    //           financierbank_id: null,
    //           financieraccountnumber: null,
    //           financierifsccode: null,
    //           internalfinancier_id: null,
    //         },
    //       };

    //       break;

    //     case "tokenstatus":
    //       return {
    //         ...state,
    //         qsLeadData: {
    //           ...state.qsLeadData,
    //           [action.payload.key]: action.payload.value,
    //           tokennumber: null,
    //           tokendate: null,
    //         },
    //       };

    //     case "insurancestatus":
    //       return {
    //         ...state,
    //         qsLeadData: {
    //           ...state.qsLeadData,
    //           [action.payload.key]: action.payload.value,
    //           insurancecompany_id: null,
    //           insurancenumber: null,
    //           insuranceexpdate: null,
    //         },
    //       };

    //     case "iscustomcolor":
    //       return {
    //         ...state,
    //         qsLeadData: {
    //           ...state.qsLeadData,
    //           [action.payload.key]: action.payload.value,
    //           color_id: null,
    //           customcolor: null,
    //         },
    //       };

    //     case "mc_internal_type":
    //       if (action.payload.value == 1) {
    //         return {
    //           ...state,
    //           qsLeadData: {
    //             ...state.qsLeadData,
    //             [action.payload.key]: action.payload.value,
    //           },
    //         };
    //       } else if (action.payload.value == 2) {
    //         return {
    //           ...state,
    //           qsLeadData: {
    //             ...state.qsLeadData,
    //             [action.payload.key]: action.payload.value,
    //             externalmcname: null,
    //             externalmcmobile: null,
    //             externalmcbank_id: null,
    //             externalmcbankacno: null,
    //             externalmcifsccode: null,
    //           },
    //         };
    //       } else if (action.payload.value == 3) {
    //         return {
    //           ...state,
    //           qsLeadData: {
    //             ...state.qsLeadData,
    //             [action.payload.key]: action.payload.value,
    //             mcamount: null,
    //             externalmcname: null,
    //             externalmcmobile: null,
    //             externalmcbank_id: null,
    //             externalmcbankacno: null,
    //             externalmcifsccode: null,
    //             mcinternal_id: null,
    //           },
    //         };
    //       }

    //     default:
    //       return {
    //         ...state,
    //         qsLeadData: {
    //           ...state.qsLeadData,
    //           [action.payload.key]: action.payload.value,
    //         },
    //       };
    //   }
    // },
    getPurchasesImageUpload: (state, action) => {
      return {
        ...state,
        loading: true,
        imageLooading: true,
        qsLeadData: {
          ...state.qsLeadData,
          [action.payload.imageType]: null,
        },
      };
    },
    purchasesImageUploadSuccessful: (state, action) => {
      return {
        ...state,
        loading: false,
        imageLooading: false,
        qsLeadData: {
          ...state.qsLeadData,
          [action.payload.ImageType]: action.payload.response.data,
          [action.payload.ImageType + "_id"]: action.payload.response.data.id,
        },
      };
    },
    getFormulaExecuter: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    FormulaExecuterSuccessful: (state, action, code) => {
      if (action.payload.formulaCode == "ESTIMATIONREPAIR") {
        return {
          ...state,
          loading: false,
          qsLeadData: {
            ...state.qsLeadData,
            totalrepairamount: parseFloat(action.payload.response).toFixed(2),
          },
        };
      } else if (action.payload.formulaCode == "PURCUSTOMERPAY") {
        return {
          ...state,
          loading: false,
          qsLeadData: {
            ...state.qsLeadData,
            customerpaybleamount: parseFloat(action.payload.response).toFixed(
              2
            ),
            customercreditamount: 0,
            customerbankamount: 0,
            customercashamount: parseFloat(action.payload.response).toFixed(2),
          },
        };
      } else if (action.payload.formulaCode == "CASHAMOUNT") {
        return {
          ...state,
          loading: false,
          qsLeadData: {
            ...state.qsLeadData,
            customercashamount: parseFloat(action.payload.response).toFixed(2),
          },
        };
      } else if (action.payload.formulaCode == "PURTOTALPAY") {
        return {
          ...state,
          loading: false,
          qsLeadData: {
            ...state.qsLeadData,
            total: parseFloat(action.payload.response).toFixed(2),
          },
        };
      }
    },
    purchaseApproval: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    getPurchaseCheckApprove: (state) => {
      return {
        ...state,
        loading: true,
        checkApprove: false,
      };
    },
    purchasesCheckApproveSuccessful: (state, action) => {
      return {
        ...state,
        loading: false,
        checkApprove: action.payload,
      };
    },
    isRejectModelVisible: (state, action) => {
      return {
        ...state,
        RejectMode: action.payload,
        purchaseRejectData: {},
        loading: false,
      };
    },
    rejectModelInputChangeValue: (state, action) => {
      return {
        ...state,
        purchaseRejectData: {
          ...state.purchaseRejectData,
          [action.payload.key]: action.payload.value,
        },
        loading: false,
      };
    },
    getPurchaseEnquiry: (state) => {
      return {
        ...state,
        purchaseEnquiryList: [],
        loading: true,
      };
    },
    purchaseEnquirySuccessful: (state, action) => {
      return {
        ...state,
        purchaseEnquiryList: action.payload.results,
        loading: false,
      };
    },
    gateInDelete: (state) => {
      console.log("hello world")
      return {
        ...state,
        loading: false,
      };
    },
    setGateInParams: (state, action) => {
      return {
        ...state,
        qsLeadParams: action.payload,
      };
    },

    isDrawerVisible: (state, action) => {
      return {
        ...state,
        drawer: action.payload,
      };
    },
    FilterInputChangeValue: (state, action) => {
      switch (action.payload.key) {
        case "counter__zone":
          return {
            ...state,
            qsLeadParams: {
              ...state.qsLeadParams,
              [action.payload.key]: action.payload.value,
              counter__cluster: null,
              counter__division: null,
              counter: null,
            },
          };

        case "counter__division":
          return {
            ...state,
            qsLeadParams: {
              ...state.qsLeadParams,
              [action.payload.key]: action.payload.value,
              counter__cluster: null,
              counter: null,
            },
          };

        case "counter__cluster":
          return {
            ...state,
            qsLeadParams: {
              ...state.qsLeadParams,
              [action.payload.key]: action.payload.value,
              counter: null,
            },
          };
        default:
          return {
            ...state,
            qsLeadParams: {
              ...state.qsLeadParams,
              [action.payload.key]: action.payload.value,
            },
          };
      }
      // return {
      //   ...state,
      //   qsLeadParams: {
      //     ...state.qsLeadParams,
      //     [action.payload.key]: action.payload.value,
      //   },
      // };
    },
    getCustomerById: (state, action) => {
      return {
        ...state,
        loading: true,
        customerByIdList: action.payload,
      };
    },
    insuranceNumberValidate: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    getRejectReasonData: (state, action) => {
      return {
        ...state,
        loading: true,
        rejectReasonObjData: action.payload,
      };
    },
    fsyncStatusUpdate: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    checkFilterStatus: (state, action) => {
      return {
        ...state,
        filterStatus: action.payload,
      };
    },
    apiError: (state, action) => {
      return {
        ...state,
        loading: false,
        formSubmitLoading: false,
        error_msg: action.payload,
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  getQsLeadList,
  purchaseListSuccessful,
  gateInListSuccessful,
  getGateInListById,
  getGateInListByIdSuccessful,
  clearPurchaseList,
  addQsLead,
  addQsLeadSuccessful,
  purchaseClear,
  getPurchaseDoc,
  SetVehicleParams,
  purchaseDocSuccessful,
  getVehicleVersion,
  getInsuranceData,
  InputChangeValue,
  getQsLeadByID,
  qsLeadByIDSuccessful,
  getVehicleData,
  vehicleDataSuccessful,
  getPurchasesImageUpload,
  purchasesImageUploadSuccessful,
  getFormulaExecuter,
  FormulaExecuterSuccessful,
  purchaseApproval,
  getPurchaseCheckApprove,
  purchasesCheckApproveSuccessful,
  isRejectModelVisible,
  rejectModelInputChangeValue,
  getPurchaseEnquiry,
  purchaseEnquirySuccessful,
  setGateInParams,
  getPurchaseHistory,
  purchaseHistoryListSuccessful,
  getSaleHistory,
  saleHistoryListSuccessful,
  gateInDelete,
  isDrawerVisible,
  FilterInputChangeValue,
  getRepairAmount,
  repairAmountListSuccessful,
  getReEstimation,
  reEstimationListSuccessful,
  getCustomerById,
  insuranceNumberValidate,
  getRejectReasonData,
  fsyncStatusUpdate,
  checkFilterStatus,
  tabClearData,
  SetGodownParams,
  apiError,
  setQsLeadData
} = qsLeadSlice.actions;

export default qsLeadSlice.reducer;
